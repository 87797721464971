<template>
  <page-section
    class="Reports"
    :page-title="$t('pages.reports.list.title')"
    :page-tooltip="$t('pages.reports.list.tooltip')"
  >
    <template #above-title>
      <dashboard-filters-v2 class="BaseCoverageFilters">
        <template #primary>
          <form-field left-icon="search" icon-class="hf-lg">
            <input
              v-model="form.name"
              :placeholder="`${$t('forms.search')}...`"
              class="input is-transparent is-size-4 has-text-weight-semibold"
              data-testid="searchReportsInput"
            >
          </form-field>
        </template>
        <template #tertiary>
          <dashboard-filters-v2-item>
            <v-button
              :loading="isGeneratingReport"
              class="is-black"
              @click="goToReportGenerator"
            >
              {{ $t("components.reports.generate_report.generate_report") }}
            </v-button>
          </dashboard-filters-v2-item>
        </template>
      </dashboard-filters-v2>
    </template>
    <load-more
      ref="reports"
      :endpoint="fetchReportsEndpoint"
      :debounce="500"
      width="column is-3-desktop is-4-tablet"
    >
      <template #item="{ item, addItem, removeItem, updateItem }">
        <report-item
          :item="item"
          :class="{
            'oxpacity-70': item.status === 'draft'
          }"
          @copied="addItem"
          @deleted="removeItem"
          @updated="updateItem"
        />
      </template>
      <template #noItems>
        <div class="m-v-m">
          <div>{{ $t('pages.reports.list.no_items') }}</div>
        </div>
        <div class="columns">
          <div class="column is-3">
            <report-item
              :item="dummyReportItem"
              class="opacity-40 is-cursor-pointer"
              @click.native="goToReportGenerator"
            />
          </div>
        </div>
      </template>
    </load-more>
  </page-section>
</template>

<script>
import { mapGetters } from 'vuex'

import { buildUrlWithParams } from '@hypefactors/shared/js/utils/UrlUtils'
import { ReportApiService, REPORTS_ENDPOINT } from '@hypefactors/shared/js/services/api/ReportApiService'

import ReportItem from '@/components/reports/ReportItem'

/**
 * @module Reports
 */
export default {
  name: 'Reports',
  components: {
    ReportItem
  },
  data () {
    return {
      isGeneratingReport: false,
      dummyReportItem: {
        id: null,
        status: 'done',
        name: 'Dummy report',
        created_at: Date.now(),
        download_url: '',
        cover: this.$asset(
          '/images/sections/performance/fallback_clipping_thumbnail.png'
        )
      },
      form: {
        name: ''
      }
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'hasFilter', 'getFilter']),
    ...mapGetters('coverage', ['appliedCoverageFilters']),
    fetchReportsEndpoint () {
      return buildUrlWithParams(REPORTS_ENDPOINT(this.activeBrandId), {
        name: this.form.name
      })
    }
  },
  methods: {
    // TODO: Potentially de-duplicate this and place it somewhere
    async goToReportGenerator () {
      this.isGeneratingReport = true

      const payload = {
        filters: this.appliedCoverageFilters,
        settings: {
          lang: this.$TranslationService.currentLanguage,
          timezone: this.currentUser.timezone
        }
      }

      try {
        const report = await ReportApiService.create(this.activeBrandId, payload)

        this.$refs.reports.addItemPrepended(report)

        const reportUrl = this.$getUrl(`/measure/${report.id}`, 'report')

        this.$redirectTo(reportUrl, 0, 'report')
      } catch (error) {
        this.$notify.error('There was a problem while initializing the report generator')

        this.$handleError(error, {
          reason: 'Could not generate report',
          data: {
            brand: this.activeBrandId,
            payload
          }
        })
      } finally {
        this.isGeneratingReport = false
      }
    }
  }
}
</script>
