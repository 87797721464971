<template>
  <div class="ReportItem has-shadow-intense">
    <background-image
      v-if="item.status === 'done'"
      v-loading="isBusy"
      class="ReportItem__content has-he"
      :src="item.cover_url"
      :style="{ backgroundSize: '150%', backgroundPosition: '25% 68%' }"
    />
    <div v-if="item.status === 'draft'" class="ReportItem__no-content p-a-xl has-text-centered is-full-width is-column is-aligned-center">
      <div class="has-text-weight-semibold is-size-5">
        Draft Report
      </div>
    </div>
    <div class="ReportItem__footer has-background-black p-a-m">
      <div class="has-text-weight-bold">
        {{ item.name || '-' }}
      </div>
      <div class="columns is-aligned-middle is-multiline is-mobile">
        <div class="column">
          <div>{{ datetime(item.created_at, 'YYYY-MM-DD') }}</div>
        </div>
        <div class="column is-narrow">
          <el-dropdown v-if="item.id" trigger="click" @command="handleCommand">
            <v-button
              class="is-plain is-white is-narrow"
              data-testid="optionsTrigger"
            >
              <span class="icon">
                <v-icon type="ellipsis-v" size="lg" />
              </span>
            </v-button>
            <template #dropdown>
              <el-dropdown-menu :data-testid="`ReportItem-${item.id}__DropDown`">
                <el-dropdown-item v-if="item.status === 'done'" command="download" :disabled="isNotReady">
                  <span class="icon">
                    <v-icon type="cloud-download" />
                  </span>
                  {{ $t('components.report_item.download_report') }}
                </el-dropdown-item>
                <el-dropdown-item v-if="item.status === 'draft'" command="edit">
                  {{ $t('components.report_item.edit_report') }}
                </el-dropdown-item>
                <el-dropdown-item command="copy">
                  {{ $t('components.report_item.copy_report') }}
                </el-dropdown-item>
                <el-dropdown-item command="delete">
                  {{ $t('components.report_item.delete_report') }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatetimeMixin from '@hypefactors/shared/js/mixins/DatetimeMixin'
import { ReportApiService } from '@hypefactors/shared/js/services/api/ReportApiService'

export default {
  name: 'ReportItem',

  mixins: [
    DatetimeMixin
  ],

  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isDeleting: false
    }
  },
  computed: {
    isBusy () {
      return this.isNotReady || this.isDeleting
    },
    isNotReady () {
      return !this.item.processed_at
    }
  },
  mounted () {
    this.$bus.$once(`Websockets/Report/${this.item.id}:ready`, (item) => {
      this.$emit('updated', item)
    })
  },
  methods: {
    handleCommand (command) {
      if (command === 'download') return this.download()
      if (command === 'edit') return this.edit()
      if (command === 'copy') return this.copy()
      if (command === 'delete') return this.delete()
    },
    download () {
      this.$redirectTo(this.item.pdf_url, 0, 'report_download')
    },
    edit () {
      const url = this.$getUrl(`/measure/${this.item.id}`, 'report')

      this.$redirectTo(url, 0, 'edit_report')
    },
    async copy () {
      try {
        const newReport = await ReportApiService.copy(this.item.id)

        const url = this.$getUrl(`/measure/${newReport.id}`, 'report')

        this.$emit('copied', newReport)

        this.$redirectTo(url, 0, 'copy_report')
      } catch (e) {
        this.$displayRequestError(e)
      }
    },
    async delete () {
      try {
        await this.$confirm(this.$t('components.report_item.confirm_delete'))
        this.isDeleting = true
        await ReportApiService.delete(this.item.id)
        this.$emit('deleted', this.item)
        this.$notify.success(this.$t('components.report_item.report_deleted'))
      } catch (e) {
        this.$displayRequestError(e)
      } finally {
        this.isDeleting = false
      }
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.ReportItem__content {
  height: 160px;
  background-color: $hf__color-black;
}

.ReportItem__no-content {
  height: 160px;
  color: $hf__color-white;
  background-color: $hf__color-gray;
}
</style>
