var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-section',{staticClass:"Reports",attrs:{"page-title":_vm.$t('pages.reports.list.title'),"page-tooltip":_vm.$t('pages.reports.list.tooltip')},scopedSlots:_vm._u([{key:"above-title",fn:function(){return [_c('dashboard-filters-v2',{staticClass:"BaseCoverageFilters",scopedSlots:_vm._u([{key:"primary",fn:function(){return [_c('form-field',{attrs:{"left-icon":"search","icon-class":"hf-lg"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"input is-transparent is-size-4 has-text-weight-semibold",attrs:{"placeholder":((_vm.$t('forms.search')) + "..."),"data-testid":"searchReportsInput"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}})])]},proxy:true},{key:"tertiary",fn:function(){return [_c('dashboard-filters-v2-item',[_c('v-button',{staticClass:"is-black",attrs:{"loading":_vm.isGeneratingReport},on:{"click":_vm.goToReportGenerator}},[_vm._v(" "+_vm._s(_vm.$t("components.reports.generate_report.generate_report"))+" ")])],1)]},proxy:true}])})]},proxy:true}])},[_c('load-more',{ref:"reports",attrs:{"endpoint":_vm.fetchReportsEndpoint,"debounce":500,"width":"column is-3-desktop is-4-tablet"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var addItem = ref.addItem;
var removeItem = ref.removeItem;
var updateItem = ref.updateItem;
return [_c('report-item',{class:{
          'oxpacity-70': item.status === 'draft'
        },attrs:{"item":item},on:{"copied":addItem,"deleted":removeItem,"updated":updateItem}})]}},{key:"noItems",fn:function(){return [_c('div',{staticClass:"m-v-m"},[_c('div',[_vm._v(_vm._s(_vm.$t('pages.reports.list.no_items')))])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[_c('report-item',{staticClass:"opacity-40 is-cursor-pointer",attrs:{"item":_vm.dummyReportItem},nativeOn:{"click":function($event){return _vm.goToReportGenerator.apply(null, arguments)}}})],1)])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }